@import '../../assets/scss/variables';

.user_registration_bg {
    width: 100%;
    height: 970px;
    position: relative;
}

.user_registration_bg:after {
    content: '';
    background: url(../../assets/images/user-registration-bg.png) no-repeat;
    height: 970px;
    display: block;
    border-radius: 12px;
}

.left_form_container {
    padding: 42px 64px 0 64px;
}

.left_form_container h1 {
    padding-bottom: 50px;
}

.left_form_container .welcome_screen {
    background: url(../../assets/images/welcome-bg.svg) center no-repeat;
    width: 117px;
    height: 85px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #3A5DB7;
}

.user_registration_bg .right_img_bottom {
    position: absolute;
    bottom: 0px;
    font-size: 24px;
    color: #3A5DB7;
    font-weight: 500;
    left: 20px;
    bottom: 20px;
    right: 27px;
    line-height: 24px;
}

.opt_screen {
    background: #fff;
    box-shadow: 0px 4px 33px 0px #00000024;
    width: 383px;
    padding: 24px;
    border-radius: 12px;
    z-index: 1001;
}

.opt_screen h2 {
    font-size: 28px;
    color: #030430;
    line-height: 36px;
    text-align: center;
}

.opt_screen input[type="text"] {
    text-align: center;
}

.opt_screen .otp_right_1 {
    margin-right: 4px;
}

.blue_text {
    color: $blue-color;
    font-weight: 500;
}

.opt_screen_large{
    width: 1280px;
    padding: 0  ;
}
.opt_screen_large input[type="text"]{
    text-align: left;
}

.opt_screen_large_pad{
    padding: $padding-24;
}

.opt_screen_sm{
    width: 480px;
    padding: 0  ;
}


@media (max-width: 768px) {
    .bg_images {
        height: 477px;
        right: 0;
    }

    .left_form_container {
        padding: 24px;
    }
    .user_registration_bg{
        background: #f0f4ff;
        height: 600px;
    }
    .user_registration_bg:after {
        height: 100%;
        display: block;
        border-radius: 12px;
        background-size: contain;
    }
}