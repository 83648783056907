@import './../../assets/scss/mixins';
@import './../../assets/scss/variables';

.sidebarNavigation{
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    @include boxShadow(0 0 5px rgba(0,0,0,0.1));
    ul{
        padding: 0;margin: 0;
        margin-bottom: 32px;
        li {
            list-style: none;
            a{
                display: flex;
                font-size: 12px;
                font-weight: 500;
                padding: 14px 16px;
                align-items: center;
                color: rgba($black, 0.7);
                text-decoration: none;
                border-left: transparent solid 4px;
                svg{
                    margin-right: 10px;
                    font-size: 18px;
                }
                &.active{
                    color: $primaryColor;
                    border-color: $primaryColor;
                    background-color: rgba($primaryColor, 0.05);
                }
                span.arrowIcon{
                    margin-left: auto;
                }
            }
        }

        .submenu{
            padding-left: 30px;
            li{
                a{
                    padding: 10px 16px;
                    &.active{
                        border-color: transparent;
                        background-color: transparent;
                    }
                }
            }
        }
    } 

    //Sidebar bottom
    .sidebarBottom{
        margin-top: auto;
        padding-bottom: 16px;
        .userInfo{
            padding: 0 16px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            img.userImg{
                width: 36px;
                height: 36px;
                flex: 0 0 auto;
                margin-right: 8px;
                border: $lightGrey solid 1px;
                @include borderRadius(100%);
            }
            .userInfoTxt{
                flex: 1;
                strong{
                    display: block;
                    margin-bottom: 0px;
                    font-size: 12px;
                    font-weight: 600;
                }
                span{
                    padding: 1px 8px;
                    background-color: $orangeColor;
                    font-size: 9px;
                    font-weight: 400;
                    display: inline-block;
                    @include borderRadius(10px);
                }
            }
        }
        ul{
            margin-top: 16px;
            margin-bottom: 0;
            li{
                a{
                    padding: 10px 16px;
                    &.logoutLink {
                        color: $primaryColor;
                    }
                }
            }
        }
    } 
}
 

