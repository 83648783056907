// @import './mixins';
@import './_variables.scss';
@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com" crossorigin');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
}

a {
	color: $linkcolor;
	text-decoration: none;
}

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.col_s_1 {
	width: 8.33%;
}

.col_s_2 {
	width: 16.66%;
}

.col_s_3 {
	width: 25%;
}

.col_s_4 {
	width: 33.33%;
}

.col_s_5 {
	width: 41.66%;
}

.col_s_6 {
	width: 50%;
}

.col_s_7 {
	width: 58.33%;
}

.col_s_8 {
	width: 66.66%;
}

.col_s_9 {
	width: 75%;
}

.col_s_10 {
	width: 83.33%;
}

.col_s_11 {
	width: 91.66%;
}

.col_s_12 {
	width: 100%;
}

.col_1 {
	width: 8.33%;
}

.col_2 {
	width: 16.66%;
}

.col_3 {
	width: 25%;
}

.col_4 {
	width: 33.33%;
}

.col_5 {
	width: 41.66%;
}

.col_6 {
	width: 50%;
}

.col_7 {
	width: 58.33%;
}

.col_8 {
	width: 66.66%;
}

.col_9 {
	width: 75%;
}

.col_10 {
	width: 83.33%;
}

.col_11 {
	width: 91.66%;
}

.col_12 {
	width: 100%;
}

[class*="col_"] {
	padding-left: 5px;
	padding-right: 5px;
}

.pad_top_16 {
	padding-top: $padding-top;
}

.pad_bottom_16 {
	padding-top: $padding-bottom;
}

.pad_right_16 {
	padding-right: $padding-right;
}

.pad_right_16 {
	padding-left: $padding-left;
}

.pad_top_8 {
	padding-top: $padding-top-8;
}

.pad_bottom_8 {
	padding-top: $padding-bottom-8;
}

.pad_right_8 {
	padding-right: $padding-right-8;
}

.pad_left_8 {
	padding-left: $padding-left-8;
}

.pad_top_4 {
	padding-top: $padding-top-4;
}

.pad_bottom_4 {
	padding-top: $padding-bottom-4;
}

.pad_right_4 {
	padding-right: $padding-right-4;
}

.pad_left_4 {
	padding-left: $padding-left-4;
}



.mar_top_16 {
	margin-top: $margin-top;
}

.mar_bottom_16 {
	margin-bottom: $margin-bottom;
}

.mar_right_16 {
	margin-right: $margin-right;
}

.mar_left_16 {
	margin-left: $margin-left;
}

.mar_top_8 {
	margin-top: $margin-top-8;
}

.mar_bottom_8 {
	margin-bottom: $margin-bottom-8;
}

.mar_right_8 {
	margin-right: $margin-right-8;
}

.mar_left_8 {
	margin-left: $margin-left-8;
}

.mar_top_4 {
	margin-top: $margin-top-4;
}

.mar_bottom_4 {
	margin-bottom: $margin-bottom-4;
}

.mar_right_4 {
	margin-right: $margin-right-4;
}

.mar_left_4 {
	margin-left: $margin-left-4;
}

.mar_top_28{
	margin-top: 28px;
}


.text_center {
	text-align: $text-center;
}

.text_left {
	text-align: $text-left;
}

.text_right {
	text-align: $text-right;
}


.d_flex {
	display: flex
}

.d_flex_l_r {
	justify-content: space-between;
}

.d_flex_end {
	justify-content: flex-end
}

.d_flex_align_item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.d_flex_center {
	justify-content: center;
}

.gray_text_color {
	color: $gray-text;
	font-size: $font-12;
}

.submit_seconday_btn {
	background: $secondary-btn-color;
	width: 100%;
	padding: 10px 64px;
	display: block;
	font-size: $font-14;
	color: #4F4F4F;
	border-radius: 8px;
	border: none;
	cursor:default !important;
}

.submit_primary_btn {
	background: $primay-btn-color;
	width: 100%;
	padding: 10px 64px;
	display: block;
	font-size: $font-14;
	color: $white-color;
	border-radius: 8px;
	border: none;
	cursor: pointer;
}

.display_inline_blk {
	display: inline-block;
	width: auto;
}

body {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: $line-height;
	background: $body-color;
}

.container {
	width: 1340px;
	margin: auto;
}

.form_group {
	width: 100%;
	margin-bottom: $margin-bottom;
}

.form_control {
	display: block;
	width: 100%;
	padding: 8px 14px;
	font-size: 16px;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form_control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.form_control_otp {
	width: auto;
	width: 40px;
}

.form_group label {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding: 0 0 8px 0;
	color: #212529
}

.form_group input::placeholder {
	font-size: 14px;
	color: #878A99;
}

.form_group a {
	text-decoration: underline;
}

.error_message {
	color: $red-error;
	font-size: $font-12;
}

.error_textbox {
	background: $red-background;
	border: 1px solid $border-error-red;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.unsuccessful_icon {
	background: url(../images/close-icon-filled.svg) center no-repeat;
	width: 92px;
	height: 92px;
	margin: auto;
	display: inline-block;
}
.close_svg_icon{
		background: url(../images/close-svg-icon.svg) center no-repeat;
		width: 24px;
		height: 24px;
		margin: auto;
		display: inline-block;
	}
.successful_icon {
	background: url(../images/successful-icon.svg) center no-repeat;
	width: 92px;
	height: 92px;
	margin: auto;
	display: inline-block;
}
.info_message_bt{
	background: #fff3cd;
    border-radius: 4px;
    padding: 8px;
    font-size: 12px;
	border: #ffeeba solid 1px;
}
.add_number_icon {
	background: url(../images/add_detail_icon.svg) center no-repeat;
	width: 16px;
	height: 16px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
}
.edit_icon{
	background: url(../images/edit_icon.svg) center no-repeat;
	width: 20px;
	height: 20px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
}
.verification_icon {
	background: url(../images/verification_icon_dec.svg) center no-repeat;
	width: 16px;
	height: 16px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	top: 20px;
	right: 20px;
}

.verify_success_icon {
	background: url(../images/verify-success-icon.svg) center no-repeat;
	width: 16px;
	height: 16px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	top: 20px;
	right: 20px;
}

.aadhar_attempt_unsuccessful_icon {
	background: url(../images/aadhar-attempt.svg) center no-repeat;
	width: 92px;
	height: 92px;
	margin: auto;
	display: inline-block;
}

.aadhar_pan_mismatch_icon {
	background: url(../images/pan-aadhar-mismatch-image.svg) center no-repeat;
	width: 92px;
	height: 92px;
	margin: auto;
	display: inline-block;
}

.attempt_reached_icon {
	background: url(../images/attempt-reached.svg) center no-repeat;
	width: 92px;
	height: 92px;
	margin: auto;
	display: inline-block;
}

.info_icon {
	background: url(../images/info-icons.svg) center no-repeat;
	width: 16px;
	height: 16px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
}
.document_icon{
	background: url(../images/document-icon.svg) center no-repeat;
	width: 16px;
	height: 16px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
}
.image_view_icon{
	background: url(../images/image_view_icon.svg) center no-repeat;
	width: 24px;
	height: 24px;
	margin: auto;
	display: inline-block;
	vertical-align: middle;
}

.capcha_font_size {
	font-size: 19px;
}

.pos_rel {
	position: relative;
}
.pos_rel_tr {
	top: -2px;
    left: 0;
    display: inline-block;
    position: relative;
}

.top_header {
	background: $white;
}

.top_header h1 {
	padding: 48px 24px;
}

.custom_stepper_container {
	width: 980px;
	margin-bottom: 16px;
}

.custom_stepper {
	background: #F6F7F9;
	padding: 16px 24px;
	border-radius: 8px;
}

.custom_stepper_red {
	background: #cc3300;
}

.success_message {
	background: #DAFEE6;
	border-radius: 8px;
	padding: 4px 12px;
}

.images_bg {
	border: #D1D1D1 solid 1px;
	border-radius: 8px;
	padding: 5px;
	width: 120px;
}
.images_bg img{
	max-width: 100%;
}
.image_preview_details {
	width: 600px;
}

.form_group_gray_bor {
	border: #D6D9E1 solid 1px;
	border-radius: 8px;
	padding: 16px 0 0 16px;
}

.d_block {
	display: block;
}

.disbled_bg {
	background: #EDEEF1;
 }

.card_title{
	background: #F6F7F9;
	border-radius: 8px 8px 0 0;
}
.card_content{
	background: #fff;
	border-radius:8px;
	padding: 24px;
}
.blue_circle{
	background: #ADDDFF;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	display: inline-block;
	text-align: center;
}
.thanks_messsage_blue{
	color: #2D0C69;
	font-size: 14px;
	font-weight: 400;
}
.otp_input_box{
    background: #fff;
    border: #CED4DA solid 1px;
    border-radius: 4px;
	padding: 8px 16px;
}
.date_height input{
	padding: 9px 16px;
}
.date_width{
	width: 100%;
}
.date_padding_none label + div{
	padding-top:0 !important;
}
.info_message{
	position: relative;
	margin-left: 16px;
}

.info_message .info_hide{
	position: absolute;
	top: -7px;
	left: 22px;
	right: 0px;
	display: none;
	background: #fff3cd;
    width: 330px;
    border-radius: 4px;
    padding: 8px;
    font-size: 12px;
	border: #ffeeba solid 1px;
}
.info_message .info_icon{
	cursor: pointer;
}
.info_message .info_icon:hover + .info_hide {
	display: block;
}
.images_viewer{
	background: #cc3300;
}

.thickbox_gray_bg{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color:rgba(0, 0, 0, 0.7) ;
	display: 'flex';
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.thickbox_whtie_box{
	background: #fff;
	padding: 16px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	max-width: 40%;
	height: 430px;
	overflow: auto;
	margin: auto;
	top: 50px;
	left: 0;
	right: 0;
	position: absolute;
	overflow-y: hidden;
}

.thickbox_whtie_box button{
	border: none;
	background:transparent;
	float: right;
}
iframe.thickbox_whtie_iframe{
   width: 100%;
   height: 100%;
   border: none;
}
iframe.thickbox_whtie_iframe img{
	max-width: 100%;
	height: 370px;
}
.global_loader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color:rgba(0, 0, 0, 0.7) ;
	display: 'flex';
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.global_loader::after{
	content: '';
	background: url(../images/tramo-loader-file.gif) center no-repeat;
	width: 90x;
	height: 90px;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}
.red{
    background: #cc3300;
}

.stepperContainer {
    transition: background-color 0.3s ease;
  }
  .step {
    transition: background-color 0.3s ease;
    padding: 16px 16px;
    background: #f7f7f7;
    border-radius:8px ;
  }

  .activeStep {
    background-color: #06226A; 
    padding: 16px 16px;
    color: #fff;
  }
  .activeStep span{
    color: #fff !important;
  }

  .completedStep {
    background-color: #21cf5b;
  }
  .completedStep svg{
    color: #0DA344 !important;
  }
@media (max-width: 768px) {
	[class*="col_"] {
		padding: 0px;
	}

	[class*="col_s"] {
		width: 100%;
	}

	.container {
		width: 100%;
	}
	.row {
		display: block;
	}
	.mobile_hr_scroll{
		overflow-y: scroll;
	}
	.mobile_d_flex{
		display: flex;
	}
}