@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.blue_background {
    background-color: #06226A !important;
}

.blue_background button:hover {
    background-color: #EAF6FF !important;
    color: #06226A;
    border-radius: 0px;
}

.active_nav {
    background-color: #EAF6FF !important;
    color: #06226A !important;
    border-radius: 0px !important;
    padding: 20px 30px !important;
}

.navbar_top {
    padding: 20px !important;
}

.navbar_top:hover {
    background: #EAF6FF;
}

.navbar_top span {
    display: block;
}

ul.available_balance,
ul.gst_balance {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #06226A;
    font-weight: 700;
    font-size: 12px;
}

.vender_link {
    color: #fff;
    background: #06226A;
    padding: 8px 16px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    border-radius: 12px;
    display: inline-block;
    margin-top: 2px;
    font-size: 12px;
}

.vender_link:hover {
    color: #fff;
    text-decoration: none;
}

.admin_top_icon_1 {
    background: url(../../../assets/images/top-icon-301124-1.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_2 {
    background: url(../../../assets/images/top-icon-301124-4.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_3 {
    background: url(../../../assets/images/top-icon-301124-5.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_4 {
    background: url(../../../assets/images/top-icon-301124-6.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_5 {
    background: url(../../../assets/images/top-icon-301124-7.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_6 {
    background: url(../../../assets/images/top-icon-301124-8.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_7 {
    background: url(../../../assets/images/top-icon-301124-3.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.admin_top_icon_8 {
    background: url(../../../assets/images/top-icon-301124-2.svg) center no-repeat;
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
}

.home_icon_size {
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 0 4px 0 0;
}

.home_icon {
    background: url(../../../assets/images/dashboard-home-img.svg) center no-repeat;
}

.our_network_icon {
    background: url(../../../assets/images/our_networks.svg) center no-repeat;
}

.analytics_icon {
    background: url(../../../assets/images/analytics_img.svg) center no-repeat;
}

.reconciliation_icon {
    background: url(../../../assets/images/reconciliation_img.svg) center no-repeat;
}

.funds_icon {
    background: url(../../../assets/images/funds_img.svg) center no-repeat;
}

.controls_icon {
    background: url(../../../assets/images/controls_img.svg) center no-repeat;
}

.tools_icon {
    background: url(../../../assets/images/tools_img.svg) center no-repeat;
}

.api_icon {
    background: url(../../../assets/images/api_img.svg) center no-repeat;
}



.noti_top_icon_1 {
    background: #39DD75;
}

.noti_top_icon_2 {
    background: #06226A;
}

.noti_top_icon_3 {
    background: #E89028;
}

.noti_top_icon_4 {
    background: #E0C432;
}

.noti_top_icon_5 {
    background: #E73C3C;
}

.noti_top_icon_6 {
    background: #E73C3C;
}

.noti_top_icon_7 {
    background: #0682FF;
}

.noti_top_icon_8 {
    background: #FF5630;
}

.noti_common {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: inline-block;
    color: #fff;
    text-align: center;
    position: absolute;
    top: -11px;
    right: -12px;
    font-style: normal;
}

.navbar_admin {
    background: #06226a;
}

.navbar_admin ul {
    display: flex;
}

.sub_menu_block {
    display: none;
}

.nav {
    background-color: #06226A !important;
}

.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.menu_item {
    position: relative;
    padding: 15px 20px;
    color: #fff;
    cursor: pointer;
}

.menu_item:hover {
    background-color:#2b51b1;
    color: #fff;
}

/* Styling for the submenu */


.submenu_item {
    padding: 10px 20px;
    color: #06226A;
    cursor: pointer;
}
.submenu_item a{
    color: #06226A;
}
.submenu_item:hover {
    background-color: #EAF6FF;
    color: #06226A;
}

/* Show submenu on hover */
.menu_item:hover .submenu {
    display: block;
    width: 210px;
}

.menu_item:hover .submenu a{
   text-decoration: none;
}


.submenu .menu_item:hover{
    color: #fff;
}
.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #EAF6FF;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #06226A;
    z-index: 9999;
}
ul.submenu .menu_item a{
    color: #06226A;
}
ul.submenu .menu_item:hover a{
    color: #fff;
}
 