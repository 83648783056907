@import './mixins';
@import './variables';
@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com" crossorigin');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



body { 
  background-color: $lightGrey_v1;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin: 0;
}

label{
  margin: 0;
}

table th{font-weight: 600;}
table td{padding: 10px 16px;}

.css-hip9hq-MuiPaper-root-MuiAppBar-root{
  background-color: #fff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05) !important;
} 
 
.MuiDialogContent-root .form-bx{padding: 0;}
.MuiDialogContent-root .MuiPaper-elevation{box-shadow: none;}
.close-button{position: absolute !important; right: 10px; top: 10px;}
 


//New CSS
.label-control{
  display: block;
  font-size: 12px;
  color: $grey_v3;
  font-weight: 500;
  margin-bottom: 4px;
  span{
    color: $primaryColor;
  }
} 

.customAutoField{
  .MuiOutlinedInput-root{
    padding-top: 8px!important;
    padding-bottom: 8px!important;
    font-size: 12px;
  }
}

//Datepicker field style 
.datepickerStyle{
  display: block!important;
  .MuiInputBase-root{
    display: flex;
    font-size: 12px;
    line-height: normal;
    font-family: 'Inter', sans-serif;
    input{
      padding: 17px 14px;
      height: auto;
    }
  }
} 


//Form number field
.phoneNumberField{
  .w65{
    width: 65px;
    flex: 0 0 auto;
  }
  select.form-control{
    border-right: none;
    height: 48px;
    @include borderRadius(5px 0 0 5px);
  }
  input.form-control{
    border-left: none;
    height: 48px;
    @include borderRadius(0 5px 5px 0);
  }
}

.btn{
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 600;
  @include borderRadius(4px);
}
.btn-outline-primary{
  border-color: $primaryColor;
  color: $primaryColor;
  &:hover{
    background-color: transparent;
    border-color: $primaryColor_v2;
    color: $primaryColor_v2;
  }
  &:focus, &:active{
    box-shadow: none!important;
    outline: none;
    color: $primaryColor_v2!important;
    border-color: $primaryColor_v2!important;
    background-color: transparent!important;
  }
}
.btn-primary{ 
  background-color: $primaryColor;
  color: $white;
  border: $primaryColor solid 1px;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 600;
  @include borderRadius(4px);
  &:hover{
    background-color: $primaryColor_v2;
    border-color: $primaryColor_v2;
  }
  &:focus, &:active{
    box-shadow: none!important;
    outline: none;
    border-color: $primaryColor_v2!important;
    background-color: $primaryColor_v2!important;
  }
  &:disabled, &:disabled:focus {
    background-color: rgba(0,0,0,0.25)!important;
    border: none;
  }
}

.headingRow{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1{
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
}

.errorMsg{
  padding: 5px 0;
  font-size: 11px;
  color: $primaryColor;
  font-weight: 500;
}

.whiteContainer{
  background-color: $white;
  padding: 20px;
  @include boxShadow(0 0 10px rgba(0,0,0,0.05));
  @include borderRadius(8px);

  //Paper CSS
  .css-1fw5qro-MuiPaper-root{
    @include borderRadius(0);
    @include boxShadow(none);
    border: $lightGrey_v1 solid 1px;
  }
  //Table Container CSS
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    @include borderRadius(0);
    @include boxShadow(none);
  }

  //Tabs CSS
  .css-z0j9vg-MuiPaper-root-MuiTabs-root,
  .MuiTabs-root{
    @include borderRadius(0);
    @include boxShadow(none);
    border-bottom: $lightGrey solid 1px;
    margin-bottom: 0!important;
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
      color: $primaryColor;
    }
    .css-1aquho2-MuiTabs-indicator{
      background-color: $primaryColor;
    }
  } 
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    @include mdScreen{
      font-size: 12px;
      padding: 12px;
    }
  }

  //Table pagination
  .css-jtlhu6-MuiTablePagination-root{
    border: none;
    font-size: 12px;
    p{
      margin: 0;
      font-size: 12px;
    }
  }
}

//Circular Paginaiton
.css-wjh20t-MuiPagination-ul {
  margin-bottom: 20px!important;
  .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: $primaryColor!important;
  }
}

.tableSearchFilter{
  @include smScreen{
    width: 100%;
  }
  input{
    width: 240px;
    @include smScreen{
      width: 100%;
    }
  }
}

.dataTable{
  font-size: 12px;
  thead {
    th{
      background-color: $lightGrey_v2;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      color: $grey_v4;
      padding: 12px;
      border-color: $lightGrey_v1;
    }
  }

  tbody{
    tr{
      &:hover{
        background-color: rgba(255, 165, 0, 0.1);
        @include boxShadow(0 0 5px rgba(0,0,0,0.1));
      }
      &.reassign{
        border-left: $primaryColor solid 2px;
        background-color: rgba($primaryColor,0.1);
        &:hover{
          background-color: rgba($primaryColor,0.15);
          @include boxShadow(none);
        }
      }
    }
    td{
      font-size: 12px;
      color: $grey_v4;
      padding: 12px 12px;
      border-color: $lightGrey_v1;
      a{
        color: $primaryColor;
      }
    }
  } 
}


//Create Role Section 
.createMainTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  margin: -16px -16px 16px;
  background-color: $white;
  @include boxShadow(0 0 5px rgba($black, 0.1));
  h2{
      font-size: 16px;
      color: $darkGrey_v3;
  }
}

.createSection{
  background-color: $white;
  @include boxShadow(0 0 3px rgba($black, 0.05));
  .createSubTitle{ 
      font-size: 14px;
      font-weight: 500;
      color: $darkGrey_v4;
      margin: 0;
      padding: 14px 16px;
      border-bottom: $lightGrey_v1 solid 1px; 
  }

  .createFormSection{
    padding: 16px;
    textarea{
      height: 160px;
      &.h_90{
        height: 90px;
      }
    }
    .css-ahj2mt-MuiTypography-root{
      font-size: 12px;
      word-break: break-word;
      margin-right: 10px;
    }
    .css-12q9u1p-MuiButtonBase-root-MuiRadio-root.Mui-checked,
    .css-m94tiq-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
    .css-m94tiq-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
      color: $primaryColor;
    }
  }

}

.editBtn{
  svg{
      width: 18px;
      height: 18px;
      color: $primaryColor;
  }
}


//Google signin button
.g_id_signin{
  display: flex;
  justify-content: center;
}