@import './../../assets/scss/mixins';
@import './../../assets/scss/variables';

.layoutWrapper{
    min-height: 100vh;
    padding-top: 64px;
    .sidebar{
        width: 240px;
        flex: 0 0 auto;
        position: fixed;
        top: 64px;
        bottom: 0;
        left: 0;
    }
    .viewArea{ 
        padding: 24px;
        margin-left: 240px;
        @include mdScreen {
            flex: inherit;
            margin: 0;
            width: 100%;
            padding: 20px 16px;
        }
    }
}

.noSidebarLayout{
    padding: 24px;
}