@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.background_color {
    background: #f7f7f7;
    padding: 0 0 40px 0;
    height: 100vh;
}

.loginForm {
    background: #fff;
}

.login_left_space {
    padding: 20px;
}

.login_left_space h1 {
    font-size: 32px;
    padding: 0 0 8px 0;
    margin: 0;
}

.login_left_space .red {
    color: #ff0000;
}

.login_left_space label {
    font-weight: 700;
    padding: 0 0 8px 0;
    margin: 0;
}

.login_rgt_bg {
    background: url('../../assets/images/user-registration-bg.jpg') center no-repeat;
    width: 100%;
    height: 400px;
    margin: auto;
    display: inline-block;
}

.passwrordField {
    position: relative;
}

.passwrordField Button {
    position: absolute;
    top: 2px;
    right: -6px;
}

.submit_button {
    background: $primay-btn-color;
    width: 100%;
    padding: 10px 64px;
    display: block;
    font-size: $font-14;
    color: $white-color;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.forgotPassword {
    margin: 8px 0 0 0;
}

.form_group {
    width: 100%;
    margin-bottom: $margin-bottom;
}

.form_control {
    display: block;
    width: 100%;
    padding: 8px 14px;
    font-size: 16px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form_control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}