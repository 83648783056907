@mixin boxShadow($val){
    box-shadow: $val;
    -moz-box-shadow: $val;
    -webkit-box-shadow: $val;
}

@mixin borderRadius($val){
    border-radius: $val;
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
}


// Responsive media Queries
@mixin lgScreen {
    @media screen and (max-width: 1170px) {
        @content;
      }
}

@mixin mdScreen {
    @media screen and (max-width: 992px) {
        @content;
      }
}

@mixin smScreen {
    @media screen and (max-width: 778px) {
        @content;
      }
}

@mixin xsScreen {
    @media screen and (max-width: 575px) {
        @content;
      }
}