.right_border{
    border-right: #C2B2FF solid 1px;
}
.left_border{
    border-left: #C2B2FF solid 1px;
}
ul.login_dropdown{
    padding: 0;
    margin: 0;
    list-style: none;
    color: #06226A;
    font-weight: 700;
    text-align: left;
}
ul.login_dropdown button{
    padding: 0 !important;
    margin: 0 !important;
    text-align: left !important;
}
.admin_text_sm{
    color:#ccc;
    display: block;
}
ul.notification, ul.notification li{
    padding: 0;
    margin: 4px 22px 0 0;
    list-style: none;
    list-style-type: none;
}
.dashboard h1{
    padding: 32px 0 16px 0;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 9px 6px;
}
.css-11t8wvo-MuiAccordionDetails-root{
    border: none !important;
}
.css-zsq1z8-MuiPaper-root-MuiAccordion-root, .css-zsq1z8-MuiPaper-root-MuiAccordion-root{
    box-shadow: none!important;
}
.css-zsq1z8-MuiPaper-root-MuiAccordion-root::before{
    background: none !important;
}
.css-bsph2u-MuiTableCell-root{
    border-bottom: none!important;
}
.css-eqpfi5-MuiAccordionSummary-content{
    display: block !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 8px 12px;
}
.menu-item:hover {
    background-color: #EAF6FF;
    cursor: pointer;
 }

 
