@import '../../../assets/scss/variables';

.bg_color {
    background: #F3F3F9;
    padding: 24px 0;
}

.complete_registration {
    background: $white;
    box-sizing: border-box;
    padding: 24px 16px;
    border-radius: 12px;
    border-top: #C0C2F6 solid 1px;
    border-right: #C0C2F6 solid 1px;
    border-left: #C0C2F6 solid 1px;
    border-bottom: #C0C2F6 solid 1px;
}

.complete_registration h2 {
    color: $headingcolor;
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    padding-bottom: 24px;
}

.confirm_details {
    border: #D6D9E1 solid 1px;
    border-radius: 12px;
    overflow: hidden;
}

.confirm_details_heading {
    background: #EAF6FF;
}

.confirm_details h3 {
    color: #06226A;
    padding: 16px;
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    position: relative;
}

.confirm_details .text_number {
    color: #B3B9C6;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin: 0 10px 0 0;
}

.confirm_details_form {
    padding: 16px;
}

.confirm_details_form .add_number {
    border: #D6D9E1 solid 1px;
    border-radius: 8px;
    padding: 8px 24px;
}

.confirm_details_form a.add_number {
    color: #06226a;
    font-size: 14px;
    font-weight: 500;
}

.confirm_details_form a.add_number:hover {
    text-decoration: none;
}

.submit_text_bg {
    background: #F6F7F9;
    overflow: hidden;
    padding: 16px;
    border-bottom: #C0C2F6 solid 1px;
    border-right: #C0C2F6 solid 1px;
    border-left: #C0C2F6 solid 1px;
    border-radius: 0 0 12px 12px;
}

.submit_text_bg_ {
    background: #F6F7F9;
    overflow: hidden;
    padding: 16px;
}

.user_onboard_conform {
    width: 433px;
    height: 182px;
    background: $white;
    border: 12px;
    box-shadow: 0px 2px 2px 0px #0000001F;
    padding: 32px;
    font-size: 14px;
    color: #6A768D;
    border-radius: 12px;
}

.user_onboard_conform p {
    padding: 0 0 32px 0;
}

.user_onboard_conform strong {
    color: #3C4250;
}

.otp_message_ {
    color: #6A768D;
    font-size: 12px;
    padding: 10px 0 0 0;
}

.otp_message_ a {
    color: #0682FF;
    font-size: 12px;
    text-decoration: none;
    font-weight: 700;
}

.otp_message_ a.resend_otp {
    text-decoration: underline;
}

.complete_registration__ {
    background: $white;
    box-sizing: border-box;
    padding: 24px 16px;
    border-radius: 12px;
}

.aadhar_otp_number .mobile_number {
    color: #3D3D3D;
    font-size: 20px;
    line-height: 24px;
    margin-right: 24px;
}

.aadhar_otp_number .change_mobile_number {
    color: #0682FF;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-decoration: none;
}

.pan_details {
    border: #D6D9E1 solid 1px;
    border-radius: 12px;
    overflow: hidden;
    padding: 8px 16px;
    margin-top: 16px;
}

.pan_details .gray_text {
    color: #B3B9C6;
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    margin: 0 8px 0 0;
}

.pan_details h3 {
    color: #40436F;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
}

.seeding_status_not {
    background: #f3bfbf;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: rgb(233, 12, 12);
    text-align: center;
    padding: 4px 8px;
}

.confirm_details h3.border_line::after {
    background: #D6D9E1;
    width: 100%;
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 29px;
    left: 146px;
}

.constitution_type {
    padding: 12px 16px;
    border: #D6D9E1 solid 1px;
    border-radius: 8px;
    color: #6A768D;
    font-size: 14px;
    line-height: 20px;
}

.constitution_type span {
    font-size: 16px;
    font-weight: 700;
    color: #06226A;
    display: block;
}

.images_format_bg {
    position: relative;
    padding: 16px;
    height: 50px;
}

.images_format_bg:after {
    background: #f7f7f7;
    height: 1px;
    width: 100%;
    content: '';
    display: block;
    position: absolute;
    bottom: 24px;
    z-index: 0;
}

.images_format_bg .text_center {
    background: #fff;
    z-index: 9;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 177px;
    text-align: center;
}

.upload_photo {
    border: #D6D9E1 solid 1px;
    border-radius: 8px;
    ;
    padding: 12px 16px;
    margin-bottom: 16px;
}

.upload_photo h4 {
    color: #06226A;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 0 16px;
    position: relative;
}
.upload_photo h4:before{
    content: '';
    width: 5px;
    height: 5px;
    background: #D6D9E1;
    left: 0;
    position: absolute;
    top: 17px;
    border-radius:50%;
}
.upload_photo_icon {

    position: relative;
    display: inline-block;
}

.upload_photo_icon input[type="file"] {
    display: none;
}

.file_label {
    background: #D1EBFF;
    border: #D1EBFF solid 1px;
    padding: 10px 24px;
    color: #06226A;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 8px;
}

.file_name {
    margin-right: 20px;
    font-size: 14px;
}

.business_info_text{
    font-size: 14px;
    color: #40436F;
    font-weight: 400;
    line-height: 20px;
}
.bank_details{
    border-bottom: #E0E0FF solid 1px;
    display: inline-block;
    padding: 0 0 10px 0;;
}

.bank_details h4{
    color: #3C4250;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  
}
.bank_account_details{
    margin: 16px;
}
.bank_account_details .header{
    background: #F6F7F9;
    border-radius:8px 8px 0 0;
    padding: 10px 0;
    color: #3C4250;
    font-size: 14px;
    font-weight: 700;
}
.bank_account_details .body{
    background: #fff;
    border-radius:0 0 8px 8px;
    padding: 10px 0;
    color: #3C4250;
    font-size: 14px;
    font-weight: 400;
    border-bottom:#f7f7f7 solid 4px;
    border-left:#f7f7f7 solid 1px;
    border-right:#f7f7f7 solid 1px;
}
.bank_account_details .body .logo{
    width: 40px;
    height:40px;
    border: #E7E7E7 solid 1px;
    border-radius:50%;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px 0 0;
}
.bank_account_details .body .logo img{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    top: 0;
    bottom: 0;
}
.add_bank_account{
    background: #fff;
    border-radius:8px;
    padding: 8px 16px;
    color: #06226A;
    text-decoration: none;
    display: inline-block;
    margin: 6px 0 0 0;
    border: #06226A solid 1px;
}
a.add_bank_account{
    text-decoration: none;
}
.add_bank_account .add_icon{
    font-size: 20px;
    font-weight: 900; 
    font-style: normal;
    margin-right: 4px;
}

.add_new_participants {
  border: #E0E0FF solid 1px;
  border-radius: 12px;
  padding: 16px;
}
 
.add_new_participants h4{
    border-bottom: #E0E0FF solid 1px;
    color: #40436F;
    font-size: 16px;
    padding: 0 0 8px 0;
    margin: 0 0 8px 0;
    display: inline-block;
  }
.edit_participants{
    background: #F6F6F6;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
}
.edit_participants ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.edit_participants ul li{
    float: left;
}
.edit_participants ul li .participants_number{
   color: #8A8DBD;
   font-size: 14px;
   font-weight: 400;
   font-style: normal;
   margin-right: 8px;
}
.edit_participants ul li .participants_name{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    margin-right: 8px;
    background: #3ADA40;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
 }
 .edit_participants ul li .participants_full_name{
    color: #40436F;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
 }
 .padding_top_space{
    padding: 40px 0 0 0 !important;
 }

 .user_aadharcard_right_error{
    padding-right: 75px;
 }
 .width_pan_btn{
    width: 320px;
  }
  .first_character{
    width: 20px;
    height: 20px;
    background: #cc3300;
    color: #fff;
    border-radius: 50%;
    display: inline-block;
    font-style: normal;
    text-align: center;
    overflow: hidden;
    margin: 0 10px;
    text-transform: uppercase;
  }
  .otp_bold{
    font-weight: 700;
  }
 @media (max-width: 768px) {
    .complete_registration{
        padding: 08px;
    }
 }