.radio_button {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
.radio_button input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background:#ffffff;
    border-radius: 50%;
    border:#06226A solid 2px; 
  }
  
  .radio_button:hover input ~ .checkmark {
    background-color: #ffffff;
  }
  
.radio_button input:checked ~ .checkmark {
    background-color: #06226A;
  }
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
 .radio_button input:checked ~ .checkmark:after {
    display: block;
  }
  
.radio_button .checkmark:after {
       top: 1px;
      left: 1px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #06226A;
      border: #fff solid 2px;
  }



.label_text{
  color: #06226A;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding: 0 0 0 36px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top:2px;
  }

.checkbox_button {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
.checkbox_button input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
  }
  
.checkbox_button:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
.checkbox_button input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
.checkbox_button input:checked ~ .checkmark:after {
    display: block;
  }
  .MuiStepper-horizontal{
    margin-bottom: 20px;
    width: 80%;
  }
.checkbox_button .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
