.p-stepper-action {
    background: #f7f7f7;
    padding: 12px;
    border-radius: 8px;
}

.p-stepper .p-stepper-header {
    padding: 0;
}

.p-highlight>.p-stepper-action {
    background: #06226A;
    color: #fff;
}

.p-highlight>.p-stepper-action>.p-stepper-title {
    color: #fff;
}

.p-stepper .p-stepper-separator {
    margin-inline-start: auto;
}

.card {
    border: none;
}

.p-stepper .p-stepper-panels {
    padding: 0;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    background: #fff;
    color: #06226A;
}

.p-accordion-header {
    background: #EAF6FF;
    position: relative;
}

.p-accordion-header svg {
    display: none;
}

.p-accordion .p-accordion-content,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border: #C0C2F6 solid 1px;
}

a.p-accordion-header-link{
    color: #06226A !important;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}
a.p-accordion-header-link:hover{
  text-decoration: none;
}
 
.p-accordion-header:before{
    content: '';
    position: absolute;
    left: 14px;
    top:25px;
    background:#0682FF;
	width: 8px;
	height: 8px;
    border-radius: 50%;
    display: inline-block;
}
 
.p-accordion-header-text{
    padding-left: 16px;
}

.activeStep {
    color: #1976d2;  /* Color for the active step */
  }
  
  .completedStep {
    color: #4caf50;  /* Green color for completed steps */
  }
  
  .step {
    margin-bottom: 15px;
  }
  