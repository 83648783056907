//Colors Variable
$primaryColor: #ff6869;
$primaryColor_v2: #e93f3f;
$greenColor: #25CD25;
$orangeColor: #FFA500;
$white: #FFFFFF;
$black: #000000;

$lightGrey: #dddddd;
$lightGrey_v1: #f5f5f5;
$lightGrey_v2: #f9f9f9;

$darkGrey_v1: #111111;
$darkGrey_v2: #222222;
$darkGrey_v3: #333333;
$darkGrey_v4: #444444;

$grey_v1: #999999;
$grey_v2: #888888;
$grey_v3: #777777;
$grey_v4: #666666;
$grey_v5: #555555;
$headingcolor:#030430;

$body-color: #fff;
$primay-btn-color: #030430;
$secondary-btn-color: #D1D1D1;
$line-height:20px;
$gray-text:#888888;
$padding-bottom:16px;
$padding-top:16px;
$padding-left:16px;
$padding-right:16px;
$white-color:#fff;

$margin-bottom:16px;
$margin-top:16px;
$margin-left:16px;
$margin-right:16px;

$margin-top-8: 8px;
$margin-bottom-8: 8px;
$margin-right-8: 8px;
$margin-left-8: 8px;

$margin-right-4:4px;
$margin-top-4:4px;
$margin-bottom-4:4px;
$margin-left-4:4px;

$padding-bottom:16px;
$padding-top:16px;
$padding-left:16px;
$padding-right:16px;

$padding-top-8: 8px;
$padding-bottom-8: 8px;
$padding-right-8: 8px;
$padding-left-8: 8px;

$padding-right-4:4px;
$padding-top-4:4px;
$padding-bottom-4:4px;
$padding-left-4:4px;

$padding-24:24px;

$font-12:12px;
$font-14:14px;
$font-16:16px;

$linkcolor:#405189;
$text-center:center;
$text-right:right;
$text-left:left;

$red-error:#EF4444;
$red-background:#fff8f7;
$border-error-red:#FF897E;
$blue-color:#212529;